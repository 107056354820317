import ApiService from "@/core/services/api.service.js";

const seo = {
    get() {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/seo/details")
            .then(function(response) {
              if (response.data) {
                resolve(response.data.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
   
    update(data) {
        return  new Promise((resolve, reject) => {
          ApiService.put("api/admin/seo/update",data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },

  

}

export default seo;